<template>
  <div>
    <router-view />
    <n-loader :loading="$app.store.getter('app.load')" />
  </div>
</template>

<script>
export default {
  name: 'App',
  html() {
    return {
      title: 'App',
      meta: [
        { mid: 'theme-color', name: 'theme-color', content: '#3e4095', },
        { mid: 'viewport', name: 'viewport', content: 'initial-scale=1, viewport-fit=cover, user-scalable=no', },
      ],
    }
  },
  beforeCreate() {
    $app.auth.init()
  },
  created() {
    $app.store.action('app.initLang')
  },
}
</script>

<style lang="scss">
@import '~nast-ui/styles/global';
@import 'fonts';

@include initialize((
    default: (
        colors: (
            primary: #FAAD14,
        ),
        typography: (
            header-font: 'Roboto, sans-serif',
            text-font: '400 1em Roboto, sans-serif',
            h1-font: '400 2em var(--header-font)',
            h3-font: '500 1.2em var(--header-font)',
            text-color: #000,
        ),
    ),
));

html body{
  --body-bg: #fff;
  line-height: 1.2;
}

.container{
  padding: 0 16px;
  margin-right: auto;
  margin-left: auto;
}

.n-tabs {
  justify-content: space-between;
  background: #FFFFFF;
  margin-bottom: 12px;
  border: none;

  & > .n-tab {
    font-weight: 400;
    font-size: 16px;
    border-bottom: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child{
      border-radius: 6px 0 0 6px;
      border: 1px solid #D9D9D9;
    }

    &:last-child{
      border-radius: 0 6px 6px 0;
      border: 1px solid #D9D9D9;
    }

    &.active {
      --primary: #FAAD14;
      color: #FAAD14 !important;
    }
  }
}

.n-button{
  --n-button-border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  height: 48px;

  &.yellow{
    background-color: #FAAD14;
    color: #fff;
  }
}

.n-input {
  .n-wrapper {
    background: #EFEFEF !important;
  }
  .n-content{
    --border-color: transparent;
    background: #EFEFEF !important;
  }
}
</style>
